/**
 * To use Crypto in your tests, you need to change the '@' jest-environment jsdom to jest-environment node 
 * This will allow you to run tests using the node environment.
 * 
 * */
function generateGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0,
          v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
  
export function getNewRandomUUID() {
    try
    {
        return crypto?.randomUUID()?.toUpperCase()
    }
    catch
    {
        return generateGuid()?.toUpperCase()
    }
}