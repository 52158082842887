import {useState} from "react"

export default function Checkbox({label, value = false, onChanged, title = '', labelContext, disabled}){

    function handleChange({target}){
        onChanged?.(target.checked)
    }

    return (
        <div className='CheckBox checkboxGroup'>
            <label htmlFor={labelContext}>
                {label}
                <input
                    disabled={disabled}
                    type='checkbox'
                    className={value ? 'checked' : ''}
                    onChange={handleChange}
                    checked={value}
                    title={title}
                    id={labelContext}
                />
            </label>
        </div>
    )
}