/**
 * Convert anything to a string
 * @param jsonString JsonString to be parsed to Object.
 * @param property ->  This is optional case the dev wants to extract a specific property from the parsed object. 
 * @returns a object parsed from json string.
 */
export function tryParseJson(jsonString, property) {
   
    try {
        if (property) 
            return JSON.parse(jsonString)[property]
        else 
            return JSON.parse(jsonString)
    } catch {
        return jsonString
    }
}