import ThemeButton from '../ThemeMenu/themeButton'

/**
 * The menu from which a user picks a theme.
 * @returns  A react component for the theme menu.
 */
export default function ThemeMenu() {

    return (
        <div className="ThemeMenu">
            {/*Note: Disables the Theme Buttons for now*/}
            <ThemeButton
                injectedClass="light themeButton"
                themeName='light'
                icon={['fa', 'sun']}/>

            <ThemeButton
                injectedClass="dark themeButton"
                themeName='dark'
                icon={['fa', 'moon']}/>

            {/* <ThemeButton
                injectedClass="highContrast themeButton"
                themeName='highContrast'
                icon={['fad', 'adjust']}/>

            <ThemeButton
                injectedClass="auto themeButton"
                themeName='auto'
                icon={['fad', 'sync']}/> */}
        </div>
    )
}