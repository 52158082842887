import React, { memo, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { WithMongoPath,useMongo } from '../../MongoDb/mongoContext'
import sortByProperty from '../../Helpers/sort'
import Checkbox from '../checkBox'
import { CloseIcon } from '../commonIcon'
// we only need to remember this value while the user is dragging
let columnBeingDragged = -1

const Row = memo(({ factory, heading, className, row, isHovering, setValueFor }) => (
    <div className={`${className} tableRowItem`}
        data-testid={`${heading.trim().length > 0 ? heading.replace(/\s+/g, '').toUpperCase() : className.toString().replace(/\s+/g, '').toUpperCase()}rowItem`}
        data-header={heading}
        data-hover={isHovering}>
        {factory(row, setValueFor)}
    </div>
))

const DataAwareTableRow = memo(({ columns, showCheckBoxes, isHovering, enableColumnConfiguration, isColumnVisible }) => {
    const { state: row, setValueFor } = useMongo()

    return (
        <div className='tableRow'>
            {showCheckBoxes && <div className='tableRowItem checkboxCell' data-header='Action'>
                <Checkbox />
            </div>}
            {columns.map(({ factory, heading, columnKey, className = '' }, index) => (
                (!enableColumnConfiguration || isColumnVisible(columnKey)) &&
                <Row
                    className={className}
                    heading={heading}
                    factory={factory}
                    key={index}
                    row={row}
                    isHovering={isHovering}
                    setValueFor={setValueFor}
                />
            ))}
        </div>)
})

const TableRow = memo(({ id, ...props }) => {
    return (
        <WithMongoPath path={[id]}>
            <DataAwareTableRow {...props} />
        </WithMongoPath>
    )
})

/**
 * @function FlexGridTable
 * @returns Returns a TableGrid element.
 * @example 'we need to send column and data in the same  component where this component is imported, as well as creating the column structure, e.g:'
 * const columns = [
        {heading:'Name',draggable: false,factory: ({name = ''}) =>
            <label>{name}</label>
        },
        {heading:'Phone',draggable: false,factory: ({phone = ''}) =>
            <label>{phone}</label>
        }]
      'which represent two columns Name and Phone as well as containing the property in the object E.g: person.name or person.phone, basically we can access any data within the object by calling it.
      . We must follow this structure otherwise the Grid will fail to draw.'
 * @param columns represent columns that will be displayed on the component including their fields.
 * @param data represents the data collection.
 * @param initialRowsPerPage this is by default  8 but can be set to be the total of rows to be display on the grid if the user activate  the navigation options
 * @param footerRow represent a summary row if the component have one.
 * @param showCheckBoxes active and disable checkboxes
 * @param allowReorderColumns allow reordering columns by dragging and dropping.
 * @param showFooter shows the SummaryRow.
 * @param summaryRowData data for the summaryRow.
 * @param defaultSortField default sort field name
 * @param enableColumnConfiguration this will toggle the column configuration feature, once this is set to true the developer
 * must provide the tableColumnsLayout and the columnsKeys in addition we can provide the columnsThatCannotBeRemoved as well.
 * @param tableColumnsLayout use this to inform what is the table layout that needs to be used, e.g: unitSummary, milestones etc...
 * @param columnsKeys those will be the default columns that will show up case the user hasn't configured his layout yet.
 * @param columnsThatCannotBeRemoved those are exception column which cannot be removed using the table configuration feature, it will show a disable checkbox indicating that the option is unavailable.
 * **/
export function FlexGridTable({
    dataColumns = [],
    data = [],
    showCheckBoxes = false,
    allowReorderColumns = true,
    showFooter = true,
    summaryRowData = {},
    defaultSortField,
    enableColumnConfiguration = false,
    tableColumnsLayout,
    columnsKeys = [],
    columnsThatCannotBeRemoved = []
}) {
    //handle the max number of rows per page
    let numberOfPages = 1

    const [columns, setColumns] = useState(dataColumns)
    const [selectIndexPage, setIndexPage] = useState(1)
    const itemsPerPageList = ['All', 5, 10, 25, 50, 75, 100]
    const [hoverColumn, setHoverColumn] = useState(-1)
    const [itemsPerPage, setItemsPerPage] = useState('All')
    const itemCount = data?.length ?? 0
    const [sortField, setSortField] = useState(defaultSortField)
    const [showColumnConfig, setShowColumnConfig] = useState(false)
    const sortedData = sortField ? data.sort(sortByProperty(sortField, true)) : data;
    const [visibleColumns, setVisibleColumns] = useState(JSON.parse(localStorage.getItem(tableColumnsLayout)) ?? columnsKeys)

    useEffect(() => {
        setColumns(dataColumns);
    }, [dataColumns])

    const isColumnVisible = (columnKey) => {
        return (visibleColumns.includes(columnKey) || columnKey === 'icon')
    }
    const onDrop = (dropTargetIndex) => (event) => {
        event.preventDefault()

        if (dropTargetIndex === columnBeingDragged) return

        columns.splice(dropTargetIndex, 0, ...columns.splice(columnBeingDragged, 1))
        setColumns([...columns])
        columnBeingDragged = -2
    }
    const onDragStart = (index) => (event) => {
        columnBeingDragged = index
        event.stopPropagation()
    }
    const onDrag = (index) => (event) => {
        event.target.classList.add('grabbing')
    }
    const onDragEnd = (index) => (event) => {
        columnBeingDragged = -1
        event.target.classList.remove('grabbing')
        event.stopPropagation()
    }
    const onDragEnter = (dropTargetIndex) => (event) => {
        const lastDropTarget = event.target
        const headerColumnName = event.target.innerHTML
        if (lastDropTarget) {
            lastDropTarget?.classList.remove('dropLeft')
            lastDropTarget?.classList.remove('dropRight')
        }
        event.preventDefault()

        if (dropTargetIndex === columnBeingDragged) return
        if (headerColumnName !== '') {
            // Dropping on the Right side of the item, will add 'dropRight' class to the item
            if (dropTargetIndex > columnBeingDragged) {
                event.target.classList.add('dropRight') //Adding to the header
                const allElements = document.querySelectorAll('[data-header]')
                allElements.forEach(item => {
                    if (item.getAttribute('data-header') === headerColumnName) {
                        item.classList.add('dropRight')
                    }
                })
                if (headerColumnName === 'Plot &amp; Unit Costs') {
                    const plotUnitCostsItems = document.querySelectorAll([`data-header='Plot & Unit Costs'`])
                    plotUnitCostsItems.forEach(item => { //Add to the column items
                        item.classList.add('dropRight')
                    })
                }
            }
            // Dropping on the Left side of the item, will add 'dropLeft' class to the item
            else {
                event.target.classList.add('dropLeft') //Add to the header
                const allElements = document.querySelectorAll('[data-header]')
                allElements.forEach(item => {
                    if (item.getAttribute('data-header') === headerColumnName) {
                        item.classList.add('dropLeft')
                    }
                })
                if (headerColumnName === 'Plot &amp; Unit Costs') {
                    const plotUnitCostsItems = document.querySelectorAll([`data-header='Plot & Unit Costs'`])
                    plotUnitCostsItems.forEach(item => { //Add to the column items
                        item.classList.add('dropLeft')
                    })
                }
            }
        }
        event.preventDefault()
    }
    const onDragOver = (index, draggable) => (event) => {
        if (!draggable)
            event.stopPropagation()
        else
            event.preventDefault()
    }
    const onDragLeave = (index) => (event) => {
        event.target.classList.remove('dropRight', 'dropLeft') //Remove from the header
        const headerColumnName = event.target.innerHTML
    
        // Get all elements with a data-header attribute
        const allElements = document.querySelectorAll('[data-header]')
        allElements.forEach(item => {
            // Check if the data-header attribute matches headerColumnName
            if (item.getAttribute('data-header') === headerColumnName) {
                // Remove the classes from the matching elements
                item.classList.remove('dropRight', 'dropLeft')
            }
        })
    
        if (headerColumnName === 'Plot &amp; Unit Costs') {
            const plotUnitCostsItems = document.querySelectorAll([`data-header='Plot & Unit Costs'`])
            plotUnitCostsItems.forEach(item => { //Add to the column items
                item.classList.remove('dropLeft', 'dropRight')
            })
        }
    }
    /**
     * @function getPage
     * @returns return a page base on the number of rows that the user wants to display, we can have multiples pages and be able to navigate between them.
     * **/
    function getPage(fromSourceArray, pageNumber, pageSize) {

        if (itemsPerPage === 'All')
            pageSize = itemCount
        const result = []
        let startIndex = 0
        while (true) {
            const page = fromSourceArray.slice(startIndex, startIndex + pageSize)
            if (page.length === 0) break
            result.push(page)
            startIndex += pageSize
        }
        numberOfPages = result.length
        return result[pageNumber]

    }
    const firstRowStartingIn = () => {
        if (itemsPerPage === 'All')
            return 1

        let startingIn = (selectIndexPage - 1) * itemsPerPage + 1
        if (startingIn > itemCount)
            setIndexPage(1)
        return startingIn
    }
    const lastRowEndingIn = () => {
        if (itemsPerPage === 'All')
            return itemCount

        return Math.min((selectIndexPage * itemsPerPage), itemCount) ?? 0
    }
    let selectIsDisabled = itemCount === 0
    /**
     * @function Pagination
     * @returns the user can navigate between pages as well as define how many rows he wants to display and navigate between pages on the Grid.
     * **/
    function Pagination() {
        return (
            <div className='paginationContainer'>
                <label>Items per page </label>
                <select style={selectIsDisabled ? { cursor: 'not-allowed' } : {}} disabled={selectIsDisabled} className='textField' defaultValue={itemsPerPage} onChange={(event) => {
                    setItemsPerPage(event.target.value === 'All' ? 'All' : Number(event.target.value))
                    setIndexPage(1)
                }}>
                    {
                        itemsPerPageList.map(item => <option value={item} key={item}>{item}</option>)
                    }
                </select>
                <div className='paginationMenu'>
                    <label>
                        {firstRowStartingIn()}-{lastRowEndingIn()} of {itemCount}
                    </label>
                    <div className='iconHolder'>
                        <FontAwesomeIcon style={selectIndexPage <= 1 ? { cursor: 'not-allowed', opacity: 0.5 } : undefined} title='Previews Page' icon={['fa', 'fa-arrow-left']} data-tip onClick={() => {
                            setIndexPage(selectIndexPage >= 2 ? selectIndexPage - 1 : selectIndexPage)
                        }} />
                    </div>
                    <div className='iconHolder' >
                        <FontAwesomeIcon style={selectIndexPage === numberOfPages ? { cursor: 'not-allowed', opacity: 0.5 } : undefined} title='Next Page' icon={['fa', 'fa-arrow-right']} data-tip onClick={() => {
                            setIndexPage(selectIndexPage < numberOfPages ? selectIndexPage + 1 : selectIndexPage)
                        }} />
                    </div>
                </div>
            </div>
        )
    }

    const SummaryRow = ({ columns, data }) => {
        if (enableColumnConfiguration)
            columns = columns.filter((column) => { return visibleColumns.includes(column.columnKey) || column.columnKey === 'icon'})
        return (
            <div className='tableRow summaryRow tableFooter headingHolder'>
                {columns.map(({ heading, className = '' }, index) => (
                    <div key={index} className={`${className} tableRowItem`} data-header={heading}>
                        {data[heading] ?? ''}
                    </div>
                ))}
            </div>
        )
    }


    const TableHeader = ({ index, isDraggable, heading, className, isHovering, orderBy, setSortField }) => {

        return (<div
            id={index}
            draggable={isDraggable}
            onDragStart={onDragStart(index)}
            onDrag={onDrag(index)}
            onDragEnd={onDragEnd(index)}
            onDragEnter={onDragEnter(index)}
            onDragLeave={onDragLeave(index)}
            onDragOver={onDragOver(index, isDraggable)}
            onDrop={onDrop(index)}
            data-hover={isHovering}
            className={`${className} tableRowItem`}
            onClick={() => setSortField(orderBy)}
            title={orderBy ? `Click to order by ${heading}` : null}
        >
            {heading}
            {(index === 0 && className === 'iconCell' && enableColumnConfiguration) &&
                <div className='cogIconSection'>
                    <FontAwesomeIcon
                        onClick={() => {
                            setShowColumnConfig(isShowing => !isShowing)
                        }}
                        className='cogIcon'
                        icon={['fad', 'fa-gear']}
                    >
                    </FontAwesomeIcon>
                </div>
            }
        </div>)
    }

    const TableHeaderColumnsConfiguration = () => {

        const addOrRemoveColumn = (columnKey) => {
            let newColumns = []
            if (visibleColumns.includes(columnKey))
                newColumns = visibleColumns.filter((existingColumnKey) => { return existingColumnKey !== columnKey || columnKey === 'icon'})
            else
                newColumns = [...visibleColumns, columnKey]
            localStorage.setItem(tableColumnsLayout, JSON.stringify(newColumns))
            setVisibleColumns(newColumns)

        }
        return <div className='tableHeaderConfiguration'>
            <CloseIcon onClick={() => { setShowColumnConfig(false) }} />
            <div className='columnsList'>
                {
                    columns.map((column, index) =>
                    !columnsThatCannotBeRemoved.includes(column.columnKey) && <div key={index}>
                            {column.heading && <Checkbox
                                label={column.heading}
                                title={column.heading}
                                value={visibleColumns.includes(column.columnKey)}
                                onChanged={() => { addOrRemoveColumn(column.columnKey) }}
                            />
                            }
                        </div>
                    )
                }
            </div>

        </div>
    }
    const ColumnCell = ({ column, index }) => {
        return <TableHeader
            index={index}
            isDraggable={column.draggable ?? allowReorderColumns}
            isHovering={index === hoverColumn}
            heading={column.heading}
            className={column.className}
            orderBy={column.orderBy}
            setSortField={setSortField}
        />
    }

    return (
        <div className='Table'>
            <div className='rowCollection'>
                {showColumnConfig && <TableHeaderColumnsConfiguration />}
                <div className='tableRow tableHeader headingHolder'>
                    {showCheckBoxes && <div className='tableRowItem checkboxCell'> <Checkbox /></div>}
                    {columns.map((column, index) => (
                        (!enableColumnConfiguration || isColumnVisible(column.columnKey)) && <ColumnCell column={column} index={index} key={index} />
                    )
                    )}
                </div>
                {
                    sortedData ?
                        getPage(sortedData, selectIndexPage - 1, itemsPerPage)?.map((row, index) => {
                            return (<TableRow key={index}
                                columns={columns}
                                id={row.id}
                                row={row}
                                index={index}
                                isHovering={hoverColumn === index}
                                showCheckBoxes={showCheckBoxes}
                                enableColumnConfiguration={enableColumnConfiguration}
                                isColumnVisible={isColumnVisible}
                            />)
                        })
                        : <div className='tableRow'>No rows.</div>
                }
                {showFooter && <SummaryRow data={summaryRowData} columns={columns} />}
            </div>
            <Pagination />
        </div>
    )
}