import { Link } from 'react-router-dom'
import Logo from '../Components/logo'
import './login.sass'
import TextField from '../Components/Fields/TextField/textField'
import { useState } from 'react'
import PasswordField from '../Components/Fields/PasswordField/passwordField'
import { apiPortNumber, apiUrl } from '../MongoDb/mongoContext'
import { tryParseJson } from '../Helpers/tryParseJson'

export  const Login = () => {
    const [usuario, setusuario] = useState(null)
    const [senha, setSenha] = useState(null)

    const canSend = async () => {
        const { hostname } = document.location
        const passwordField = document.getElementById('passwordField')
        const passwordFieldValue = passwordField.value
        if (!senha && !usuario)
            return
        try {
            const user = {
                email: usuario,
                senha: senha == undefined ? passwordFieldValue : senha,
                HostName: hostname,
                RememberMe: false
            };
            fetch(`https://${apiUrl}:${apiPortNumber}/api/auth/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(user)
            })
                .then((response) => {
                    if (response.status == 200) {
                        const reader = response.body.getReader();
                        reader.read().then(({ done, value }) => {
                            var decoder = new TextDecoder();
                            const decodedResponse = decoder.decode(value)
                            const redirectUrl = tryParseJson(decodedResponse, 'redirectUrl')
                            if (redirectUrl) {
                                localStorage.setItem('email', usuario)
                                window.location.replace(redirectUrl)
                                return
                            }
                            //setFailsMessage(Login failed: ${decodedResponse})
                        })
                    }
                    else {
                        const reader = response.body.getReader();

                        reader.read().then(({ done, value }) => {
                            var decoder = new TextDecoder();
                            const decodedResponse = decoder.decode(value)
                            const reason = tryParseJson(decodedResponse, 'reason')
                            //setFailsMessage(Login failed: ${reason})
                        })
                    }
                })
                .catch(error => console.error(error))
        } catch (error) {
            console.error(error);
        }
    }
    return <div className='Login'>
        <section className="Container">
            <h1 className="Container-Title">Login</h1>
            <section className="login-box">
                <TextField 
                    value={usuario}
                    onChanged={(_user)=>setusuario(_user)}
                    label={'Nome de usúario'}
                    placeholder='Nome de usúario'
                    labelPosition={'top'}
                />
                <PasswordField 
                    value={senha}
                    onChange={(_senha)=>setSenha(_senha)}
                    label={'Senha'}
                    placeholder='Senha'
                    labelPosition={'top'}
                    validate={false}

                />
                <button onClick={canSend}>Entrar</button>
                <Link className='cadastra-se-link'  to={'/registro'}> Cadastrar-se</Link>
            </section>
        </section>
    </div>
}