import { naturalOrdering } from './equivalent'

export function getPath(context, path = []) {
    for (const member of path) {
        if (member)
            context = context[member] ?? context[member.toLowerCase()] ?? {}
    }
    return context
}

const sortByProperty = (property, descending = false) => (lhs, rhs) => {
    const compareResult = Array.isArray(property) 
        ? naturalOrdering(getPath(lhs, property), getPath(rhs, property))
        : naturalOrdering(lhs[property], rhs[property]);

    return descending ? -compareResult : compareResult;
};

export default sortByProperty;