const intoNameAndValue = {
    [Symbol.split](str) {
      const cleaveAt = str.indexOf('=')
      let name = cleaveAt > 0 ? str.substr(0, cleaveAt) : str
      if (name.endsWith('4433')) name = name.substr(0, name.length - 4)
      if (name.endsWith('443')) name = name.substr(0, name.length - 3)
      const value = cleaveAt > 0 ? str.substr(cleaveAt + 1) : undefined
      return { name, value }
    }
  }
  
  export const getCookie = (name) => {
    const cookies = parseAllCookies()
    return cookies[name]
  }
  
  export const parseAllCookies = () => {
    const cookies = {}
    document
      .cookie
      .split('; ')
      .map(cookie => cookie.split(intoNameAndValue))
      .forEach(({ name, value }) => cookies[name] = value)
    return cookies
  }
  
  export const removeCookie = (name, value = "") => {
    document.cookie = `${name}=${value}; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT;`
  }
  
  /**
   * Sets a cookie with the given name and value.
   * @param {string} name - The name of the cookie.
   * @param {string} value - The value of the cookie.
   * @param {Object} [options] - The options for the cookie.
   * @param {string} [options.path='/'] - The path for the cookie.
   * @param {Date} [options.expires] - The expiration date for the cookie.
   * @param {string} [options.domain] - The domain for the cookie.
   * @param {boolean} [options.secure] - Whether the cookie should only be sent over HTTPS.
   */
  export const setCookie = (name, value, options = {}, encodeURI = true) => {
    options = {
      path: '/',
      ...options
    }
  
    const port = window.location.port
    if (port === '4433' && name.endsWith('4433')) {
      name = name.substr(0, name.length - 4)
    } else if (port === '443' && name.endsWith('443')) {
      name = name.substr(0, name.length - 3)
    }
  
    let cookie = `${encodeURIComponent(name)}=${encodeURI ? encodeURIComponent(value): value}`
  
    if (options.expires) {
      if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString()
      }
      cookie += `; expires=${options.expires}`
    }
  
    if (options.path) {
      cookie += `; path=${options.path}`
    }
  
    if (options.domain) {
      cookie += `; domain=${options.domain}`
    }
  
    if (options.secure) {
      cookie += '; secure'
    }
  
    document.cookie = cookie
  }