import { Route, Routes } from "react-router-dom";
import { Login } from "../Autenticacao/login";
import { parseAllCookies } from "../Helpers/cookies";
import { MongoProvider, useMongo, WithMongoCollection, WithMongoPath } from "../MongoDb/mongoContext";
import Dashboard from "../Dashboard/dashboard";
import { useEffect } from "react";

const MiddleContent = ()=>{
  return <div className="middleContent">
      <Routes>
        <Route path="/" element={<Dashboard/>}/>
        <Route path="/dashboard" element={<Dashboard/>}/>
      </Routes>
  </div>
}
export default function MainPage() {
  const cookies = parseAllCookies()
  const { originalAccount } = cookies
 
  return <WithMongoCollection kind="Contas" topicID={originalAccount}>
    <WithMongoPath path={[]}>
      <MiddleContent />
    </WithMongoPath>
  </WithMongoCollection>

}

