/**
 * Converts a dictionary object to an array of values, with each value having its own 'id' property set to the corresponding key in the dictionary.
 * @param {Object} dictionary - The dictionary object to convert to an array.
 * @param {Function} [sortFunction=() => 0] - An optional function to sort the resulting array.
 * @returns {Array} An array of values from the dictionary object, with each value having its own 'id' property set to the corresponding key in the dictionary.
 */
export const dictionaryToArray = (dictionary, sortFunction = () => 0, useExistingId = false) =>
    dictionary && Object
        .entries(dictionary)
        .filter(([_, value]) => value && typeof value == 'object')
        .map(([key, value]) => {
            value.id = useExistingId? value?.id : key
            return value
        })
        .sort(sortFunction)
/**
* Converts an array of objects into a dictionary object, where each item's key is the value of its 'id' property.
* @param {Array} array - The array to convert.
* @returns {Object} A dictionary object where each item's key is the value of its 'id' property.
*/
export const arrayToDictionary = (array, isTopic) =>
{
    if(array?.length == 1)
    {
        if(isTopic)
            return array[0] ?? array
        else
        {
            const item = array[0]
            return { [item._id]: item } ?? array
        }
    }
    else
    {
        let dictionary = {};
        array.reduce((index, item) => {
            dictionary[item._id] = item;
        }, {});
        return dictionary;
    }
}
/**
 * Converts a dictionary object into an array of objects with an additional 'originalKey' property for each item.
 * @param {Object} dictionary - The dictionary object to convert.
 * @param {Function} [sortFunction=() => 0] - An optional function to sort the resulting array.
 * @returns {Array} An array of objects with an additional 'originalKey' property for each item.
 */
export const parseDicToArray = (dictionary, sortFunction = () => 0) => {
    const resultArray = [];

    Object.keys(dictionary).forEach((key) => {
        const item = dictionary[key];
        const newItem = { ...item, originalKey: key };
        resultArray.push(newItem);
    })
    return resultArray;
}