
export const USE_PRODUCTION_SERVER = false

let domain = "localhost"

let useProduction = USE_PRODUCTION_SERVER;
if (useProduction) 
{
    domain = 'fsocietyinfo.azurewebsites.net'
}
export { domain };