import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './Sass/global.sass'
import './Sass/animations.sass'
import './Sass/typography.sass'
import './Sass/mixins.sass'
import './Sass/variables.sass'
import './Sass/Themes/defaultTheme.sass'
import '../src/Components/Fields/TextField/textField.sass'
import '../src/Components/ThemeMenu/themeMenu.sass'
import '../src/Components/MessageBox/messageBox.sass'
import '../src/Dashboard/dashboardMenu.sass'
import '../src/Components/Perfil/perfil.sass'
import '../src/Components/Table/flexTableGrid.sass'
import { MongoProvider } from './MongoDb/mongoContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MongoProvider>
      <App />
    </MongoProvider>
  </React.StrictMode>
);
