import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect } from 'react'

export const setChosenTheme = (themeName) => {
    document.documentElement.setAttribute('data-theme',themeName)
    localStorage.setItem('theme', themeName)

}
/**
 * A button to change theme mode
 * @param {String} themeName The name of the theme that the button would switch the site to.
 * @param {Function} onClick A function call for when the button is clicked on
 * @param {String} injectedClass  A class that applies to the button (so that it doesn't get overridden when the theme changes).
 * @returns  A react component for changing the theme.
 */
export default function ThemeButton({themeName = "", injectedClass, icon}) {
   
    return(
      <FontAwesomeIcon 
        className={injectedClass} 
        onClick={()=>setChosenTheme(themeName)} 
        icon={icon} 
      />
    )
}