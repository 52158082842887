export default function MessageBox({message, typeMessage})
{
    let classType = 'error'
    if(typeMessage == 'confirmar')
        classType = 'confirm'
    if(typeMessage === 'aviso')
        classType = 'warning'
    if(typeMessage == 'error')
        classType ='error'
    return <div className={`MessageBox ${classType}` }>
        <span>{message}</span>
    </div>
}