import { Fragment, useRef } from 'react'
import { useState } from 'react'
//import Tooltip from '../../ToolTip/toolTip'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getlabelPositionClasses } from '../../../Helpers/getLabelPositionClasses'

export default function TextField({
    autoFocus = false,
    label,
    labelContext,
    labelPosition,
    onBlur = () => { },
    onChanged = () => { },  // when you leave the field
    onChanging = () => { }, // every time you type a character
    onValidation = () => { },
    placeholder,
    toolTip,
    useValidation = false,
    value,
    mandatory = false,
    showValidationMessageBelowInput = false,
    disable = false,
    highlight,
    datatestid
}) {

    const [isEditing, setIsEditing] = useState(false)
    const [editValue, setEditValue] = useState(value)
    const [initialValue, setInitialValue] = useState(value)
    const [validationMessage, setValidationMessage] = useState(undefined)
    const input = useRef()

    function Validation() {
        let left = input.current.offsetLeft
        let top = input.current.offsetTop
        return (
            showValidationMessageBelowInput ?
            <span className='validationMessageInRed'>{validationMessage}</span>
            :  <span style={{ left, top: top - 30 }} className={`invalidWarning notValid`}>{validationMessage}</span>
        )
    }

    const onFocus = ({ target }) => {
        setInitialValue(target.value)
        setIsEditing(true)
        setEditValue(value)
        target.select()
    }

    const onBlurInternal = ({ target }) => {
        const newValue = target.value
        if (useValidation) {
            let validationMessage = onValidation(newValue)
            if (validationMessage) {
                input.current.focus()
                setValidationMessage(validationMessage)
                setIsEditing(true)
                return
            }
        }
        if (newValue != initialValue) {
            onChanged?.(newValue)
            setInitialValue?.(newValue)
            setValidationMessage(null)
        }
        setTimeout(() => setIsEditing(false), 2000)
        onBlur()
    }

    const onValueChanging = ({ target }) => {
        setEditValue(target.value)
        onChanging?.(target.value)
    }
    const checkDisabled = () => {
        return disable ? 'Not allowed' : ''
    }
    return (
        <div className={`
                textFieldContainer
                inputField
                ${getlabelPositionClasses(labelPosition)}
            `}>
            {label && <div className='fieldLabel'><label htmlFor={labelContext}>{label}</label></div>}
            <div className="fieldDiv">
                <input
                    data-testid={`${datatestid}Field`}
                    required={mandatory}
                    id={labelContext}
                    name={labelContext}
                    autoFocus={autoFocus}
                    ref={input}
                    className={`textField ${validationMessage && 'notValid'} ${disable && 'notAllowed'} ${highlight}`}
                    value={isEditing ? editValue : value ?? ""}
                    onChange={onValueChanging}
                    onFocus={onFocus}
                    onBlur={onBlurInternal}
                    placeholder={placeholder}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter')
                            event.target.blur()
                        if (event.key === 'Escape')
                            setEditValue(value)
                    }}
                    type='text'
                    disabled={disable}
                    title={checkDisabled()}
                />
                {/*isEditing && toolTip && <Tooltip tip={toolTip} />*/}
                {validationMessage && <Validation />}
            </div>
        </div>
    )
}
