/**
 * Convert anything to a string
 * @param {*} anything 
 * @returns blank string if anything was undefined or null, otherwise converts it to a string
 * Note that true becomes "true" and false becomes "false"
 * Similarly 0 becomes "0" etc
 */
export const makeString = (anything) => (anything ?? '') + ''

/**
 * Determine the lexicographic sort ordering of two elements
 * @function naturalOrdering
 * @param {*} lhs Left Hand Side element can be anything
 * @param {*} rhs Right Hand Side element can be anything
 * @returns -1, 0, or +1 
 * 
 * -1: lhs should appear before rhs in a sorted list
 *  0: the elements are the same, ordering doesn't matter
 * +1: rhs should appear before lhs in a sorted list
 * 
 * Note if both arguments are numbers, they are compared directly, or
 * if both arguments are arrays, their first elements are compared, otherwise
 * both arguments are converted to strings using the toString function above
 * and then the strings are compared with the English localeCompare
 */
 export const naturalOrdering = (lhs, rhs) => 
    Array.isArray(lhs) && Array.isArray(rhs)
    ? naturalOrdering(lhs[0], rhs[0]) // compare first elements of arrays only
    : isNaN(lhs) || isNaN(rhs)
    ? makeString(lhs).localeCompare(makeString(rhs), 'en', { sensitivity: 'base' })
    : lhs < rhs
    ? -1
    : lhs > rhs
    ? 1
    : 0

/**
 * Determine if two elements are equivalent (see note below)
 * @function areEquivalent
 * @param {*} lhs Left Hand Side element can be anything
 * @param {*} rhs Right Hand Side element can be anything
 * @return return true if lhs is equivalent to rhs, false if they are not
 * Note if both arguments are numbers, they are compared directly, or
 * if both arguments are arrays, their first elements are compared, otherwise
 * both arguments are converted to strings using the toString function above
 * and then the strings are compared with the English localeCompare
 */
 export const areEquivalent = (lhs, rhs) => 
    0 === naturalOrdering(lhs, rhs)