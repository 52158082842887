
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { apiPortNumber, apiUrl } from "../../MongoDb/mongoContext";
import { tryParseJson } from "../../Helpers/tryParseJson";
import MessageBox from "../../Components/MessageBox/messageBox";
import TextField from "../../Components/Fields/TextField/textField";

export default function TwoFact() {
    const navigate = useNavigate();
    const [tfaCode, setTfaCode] = useState(null);
    const [failsMessage, setFailsMessage] = useState();
    let [searchParams, setSearchParams] = useSearchParams()
    const email = localStorage.getItem('email')

    const translateToPtbr = {
        'Invalid code': 'Codigo invalido',
        'Code expired': 'Codigo expirado',
        'Code already used': 'Codigo ja utilizado',
        'Code not found': 'Codigo nao encontrado'
    }
    
    const validateTwoTfa = async () => {
        try {
            const { hostname } = document.location

            const loginJSON = {
                code: tfaCode,
                HostName: hostname,
                CeoEmail: email,
            }
            fetch(`https://${apiUrl}:${apiPortNumber}/api/auth/login2fa`, {
                method: 'POST',
                //credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(loginJSON)
            })
                .then((response) => {
                    if (response.status == 200) {
                        if (response.redirected) {
                            window.location.replace(response.url)
                        } else {
                            //get cookies created.
                            const reader = response.body.getReader();
                            reader.read().then(({ done, value }) => {
                                var decoder = new TextDecoder();
                                const decodedResponse = decoder.decode(value)
                                var json = JSON.parse(decodedResponse)
                                var cookies = JSON.parse(json.cookies)
                                //add them
                                for (var cookie of cookies) {
                                    document.cookie = cookie
                                }
                                //go to main page
                                localStorage.removeItem('email')
                                window.location.replace("/")
                            })
                        }
                    }
                    else {
                        const reader = response.body.getReader();
                        reader.read().then(({ done, value }) => {
                            var decoder = new TextDecoder();
                            const decodedResponse = decoder.decode(value)
                            const reason = tryParseJson(decodedResponse, 'reason')
                            setFailsMessage(`Login failed: ${translateToPtbr[reason] ?? reason}`)
                        })
                    }
                })
                .catch(error => console.error(error))
        } catch (error) {
            console.error(error);
        }
    }
    const reSend = async () => {
        setFailsMessage('Um código será enviado para o endereço de e-mail utilizado, caso seja um e-mail válido em nosso sistema.')
        const resendCode = searchParams.get('code')
        const loginJSON = {
            ResendCode : resendCode,
            HostName: document.location.hostname,
            CeoEmail: email,
        }
        try {
            
            fetch(`https://${apiUrl}:${apiPortNumber}/api/auth/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(loginJSON)
            })
                .then((response) => {
                    if (response.status == 200) {
                        const reader = response.body.getReader();
                        reader.read().then(({ done, value }) => {
                            var decoder = new TextDecoder();
                            const decodedResponse = decoder.decode(value)
                            const redirectUrl = tryParseJson(decodedResponse, 'redirectUrl')
                            if (redirectUrl) {
                                window.location.replace(redirectUrl)
                                return
                            }
                        })
                    }
                    else {
                        const reader = response.body.getReader();

                        reader.read().then(({ done, value }) => {
                            var decoder = new TextDecoder();
                            const decodedResponse = decoder.decode(value)
                            const reason = tryParseJson(decodedResponse, 'reason')
                            setFailsMessage(`Login failed: ${translateToPtbr[reason] ?? reason}`)
                        })
                    }
                })
                .catch(error => console.error(error))
        } catch (error) {
            console.error(error);
        }
    }
    failsMessage && setTimeout(() => {
        {
            setFailsMessage(null)
        }
    }, 8000)
    return (<div className="twoFactPage">

        {failsMessage && <MessageBox message={failsMessage} typeMessage={'aviso'} />}
        <span className='span'>Codigo de verificação </span>
        <TextField useValidation={true} value={tfaCode ?? ''} onChanged={setTfaCode} onValidation={(value) => {
            if (value?.length <= 0)
                return 'O codigo de duas etapas nao pode estar vazio.'
            else
                return null
        }} />
        <button onClick={validateTwoTfa}>Verificar</button>
        {/*<h2>Use o codigo recebido no seu email para concluir esta verificação.</h2>*/}
        <button className="primaryButton" onClick={(reSend)}>Re-enviar codigo</button>
    </div>)
}