import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getlabelPositionClasses } from '../../../Helpers/getLabelPositionClasses'

const strengthCategories = {
  0: "Sua senha precisa ser mais forte, tente juntar palavras, por exemplo, 'macacoRoxo@'",
  1: "Sua senha ainda precisa ser mais forte, tente juntar palavras, por exemplo, 'macacoRoxo@'",
  2: "",//"--------------------------------------------------------------------------------------------",
  3: "",//"--------------------------------------------------------------------------------------------",
  4: "",//"--------------------------------------------------------------------------------------------"
}

export default function PasswordField({onChange, isValidHandler, validate=true, label='Password', placeholder='Password', labelPosition}){

  const [password, setPassword] = useState('')
  const [passwordClass, setPasswordClass] = useState('textField')
  const [hint, setHint] = useState('')
  const [hintClass, setHintClass] = useState('')
  const [isOkay, setIsOkay] = useState(false)
  const [isValid, setIsValid] = useState(true)
  const [passwordShown, setPasswordShown] = useState(false)

  // Password visibility toggle handler
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown)
  }

  const passwordChanging = ({target}) => {
    const password = target.value
    if (password.length < 1) {

      setPassword(target.value)
      setPasswordClass('textField')
    }
    else
      passwordChanged({target})
  }

  const passwordChanged = ({target}) => {
    const password = target.value

    onChange(target.value)

    if(validate) {
      /*
     Rule 1 – Password Length: Stick with passwords that are at least 8 characters in length. The more characters in the passwords is better, as the time taken to crack the password by an attacker will be longer. 10 characters or longer are better.
     */
      if (!password || password.length < 8) {
        setHint(strengthCategories[0])
        setHintClass('notValid')
        setIsOkay(false)
        setIsValid(false)
        isValidHandler(false)
        return
      }
      /*
     Rule 2 – Password Complexity: Your password should contain at least one character from each of the following groups.

     1. Lower case letter
     2. Upper case letter
     3. Numbers
     4. Special Characters
     */
     let lowercaseLetter = false
     let uppercaseLetter = false
     let number = false
     let specialCharacter = false
     for (let i = 0; i < password.length; i++) {
        const chr = password.charAt(i)
        if (chr.toLowerCase() != chr.toUpperCase()) {
            if (chr == chr.toLowerCase()) {
                lowercaseLetter = true
            } else {
                uppercaseLetter = true
            }
        } else if (!isNaN(chr*1)) {
            number = true
        } else {
            specialCharacter = true
        }
     }
     if (!lowercaseLetter || !uppercaseLetter || !number || !specialCharacter) {
        setHint(strengthCategories[0])
        setHintClass('notValid')
        setIsOkay(false)
        setIsValid(false)
        isValidHandler(false)
        return
     }
     setHint('')
     setHintClass('valid')
     setIsOkay(true)
     setIsValid(true)
     isValidHandler(true)
    }
  }

  return (
    <div className={`
      textFieldContainer
      inputField
      ${getlabelPositionClasses(labelPosition)}
  `}>
      <div className='fieldLabel'>
        {label && <label htmlFor="passwordField">{label}</label>}
      </div>
      <div className="fieldDiv passwordField">
        <input
          data-testid='passwordField'
          id="passwordField"
          type={passwordShown ? "text" : "password"}
          placeholder={`${placeholder}*`}
          className={isValid ? "textField" : "textField notValid"}
          onChange={passwordChanging}
          onBlur={passwordChanged}
        />
        <FontAwesomeIcon icon={passwordShown ? ['fa', 'eye-slash'] : ['fa', 'eye']} onClick={togglePassword}/>
      </div>
      <div className={`validationText ${hintClass}`}>{hint}</div>
    </div>
  )
}