import {Login} from "./Autenticacao/login";
import './App.sass'
import { MongoContext, MongoProvider, WithMongoCollection, WithMongoPath } from "./MongoDb/mongoContext";
import { Fragment, useEffect, useState } from "react";
import { parseAllCookies } from "./Helpers/cookies";
import MainPage from "./MainPage/mainPage";
import { HashRouter, Route, Routes, useNavigate } from "react-router-dom";
import Registro from "./Autenticacao/Registro/registro";
import Logo from "./Components/logo";
import { getIcons } from "./icons";
import ThemeMenu from "./Components/ThemeMenu/themeMenu";
import { setChosenTheme } from "./Components/ThemeMenu/themeButton";
import DashboardMenu from "./Dashboard/dashboardMenu";
import TwoFact from "./Autenticacao/TwoFA/twoFa";

function App() {
  //primeiro vamos checar se o usuario logado using cookies
  const [usuarioLogado, setUsuarioLogado] = useState(false)
  const [themeName, setThemeName] = useState('light')
  const storedTheme = localStorage.getItem('theme') ?? null
  const cookies = parseAllCookies()
  if(storedTheme)
    setChosenTheme(storedTheme)
  else
    setChosenTheme(themeName)
  getIcons();

  useEffect(()=>{
    if(usuarioLogado)
      return

      if(cookies?.auth)
        !usuarioLogado && setUsuarioLogado(true)
      else if(!usuarioLogado)
        window.location.href = '/#/login'

  },[])
 
  return <div className="App root" id="root">
     <HashRouter>
    <div className="topContainer">
      {!usuarioLogado ? <Logo /> : <DashboardMenu />}
    </div>
    {!usuarioLogado ?
      <div className="middleContent">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/registro" element={<Registro />} />
          <Route path="/login2fa" element={<TwoFact />} />
        </Routes>
      </div>
      : <MainPage />}
  </HashRouter>
  </div >

}

export default App;
