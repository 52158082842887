import {library} from '@fortawesome/fontawesome-svg-core'
import { faEye,faEyeSlash,faSun,faMoon,faX,faArrowLeft,faArrowRight} from '@fortawesome/free-solid-svg-icons'
export function getIcons(){
    
    library.add(
        faEye,
        faEyeSlash,
        faSun,
        faMoon,
        faX,
        faArrowLeft,
        faArrowRight
    )
    return library
} 