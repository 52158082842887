import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import React, {useEffect, useState} from "react"

    export function AddIcon({onClick =() => {},...props}){
        return(
            <div className='iconHolder'>
                <FontAwesomeIcon
                    className="AddIcon"
                    data-testid='AddIcon'
                    icon={['fad', 'fa-square-plus']}
                    onClick={onClick}
                    {...props}
                />
            </div>
        )
    }

    export function DeleteIcon({onClick =() => {},...props}){
        return(
            <div className='iconHolder'>
                <FontAwesomeIcon
                    className='DeleteIcon'
                    data-testid='DeleteIcon'
                    icon={['fad', 'fa-trash-can']}
                    onClick={onClick}
                    {...props}
                />
            </div>
        )
    }

    export function CloseIcon({onClick =() => {},...props}){
        return (
            <div className='iconHolder CloseIcon'>
                <FontAwesomeIcon
                    className='CloseIcon'
                    data-testid='CloseIcon'
                    icon={['fa', 'fa-x']}
                    onClick={onClick}
                    {...props}
                />
            </div>
        )
    }

    export function EllipsisIcon({onClick =() => {},...props}){
        return (
            <div className='iconHolder'>
                <FontAwesomeIcon 
                    className='EllipsisIcon'
                    data-testid='EllipsisIcon'
                    icon={['fad', 'fa-ellipsis']}
                    onClick={onClick}
                    {...props}
                />
            </div>
        )
    }
    export function DrawerIcon({onClick =() => {},...props}){
        return (
            <div className='iconHolder'>
                <FontAwesomeIcon 
                    className='DrawerIcon' 
                    data-testid='DrawerIcon'
                    icon={['fad', 'fa-ellipsis']} 
                    onClick={onClick}
                    {...props}                
                />
            </div>
        )
    }
    export function DashboardResizeEditIcon({onClick =() => {},...props}){
        return (
            <div className='iconHolder'>
                <FontAwesomeIcon 
                    className='DashboardResizeIcon' 
                    icon={['fad', 'fa-lock']} 
                    onClick={onClick}
                    {...props}                
                />
            </div>
        )
    }
    export function DashboardResizeLockIcon({onClick =() => {},...props}){
        return (
            <div className='iconHolder'>
                <FontAwesomeIcon 
                    className='DashboardResizeIcon' 
                    icon={['fad', 'fa-lock-open']} 
                    onClick={onClick}
                    {...props}                
                />
            </div>
        )
    }
    export function DashboardExportPDFIcon({onClick =() => {},...props}){
        return (
            <div className='iconHolder'>
                <FontAwesomeIcon 
                    className='DashboardResizeIcon' 
                    icon={['fad', 'fa-file-export']} 
                    onClick={onClick}
                    {...props}                
                />
            </div>
        )
    }
    export function DashboardPrintPDFIcon({onClick =() => {},...props}){
        return (
            <div className='iconHolder'>
                <FontAwesomeIcon 
                    className='DashboardResizeIcon' 
                    icon={['fad', 'fa-print']} 
                    onClick={onClick}
                    {...props}                
                />
            </div>
        )
    }
    export function CashflowSpreadButton({onClick =() => {}, actualText,...props}){
        return (
            <FontAwesomeIcon 
                title={`Spread option for ${actualText}`} 
                icon={['fad', 'chart-mixed']} 
                onClick={onClick} 
                {...props}
            />
        )
    }

    export function SuspendUserIcon({onClick =() => {},...props}){
        return(
            <div className='iconHolder'>
                <FontAwesomeIcon
                    className='SuspendUserIcon'
                    data-testid='SuspendUserIcon'
                    icon={['fad', 'fa-user-slash']}
                    onClick={onClick}
                    {...props}
                />
            </div>
        )
    }