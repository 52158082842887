import { Link, useNavigate } from "react-router-dom";
import TextField from "../../Components/Fields/TextField/textField";
import Logo from "../../Components/logo";
import './registro.sass'
import PasswordField from "../../Components/Fields/PasswordField/passwordField";
import { useState } from "react";
import MessageBox from "../../Components/MessageBox/messageBox";
import { getAPIPortNumber } from "../../Helpers/location";
import { apiUrl } from "../../MongoDb/mongoContext";

export default function Registro()
{   
    const [vulgo,setVulgo] = useState()
    const [email,setEmail] = useState()
    const [senha,setSenha] = useState()
    const [confirmSenha,setconfirmSenha] = useState()
    const [showMessageBox, setShowMessageBox] = useState(null)
    const [showErrorMessageBox, setShowErrorMessageBox] = useState(null)
    const [showSuccessMessageBox, setshowSuccessMessageBox] = useState(null)
    const navigate = useNavigate()
    const sendCastro = async () => {
        const loginJSON = {
            email: email,
            password: senha,
            vulgo: vulgo
        };
        const portNumber = getAPIPortNumber();
    
        try {
            const response = await fetch(`https://${apiUrl}:${portNumber}/api/register/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(loginJSON)
            });
    
            if (!response.ok) {
                const reader = response.body.getReader();
                const { done, value } = await reader.read();
                const decodedResponse = new TextDecoder().decode(value);
                setShowErrorMessageBox(decodedResponse)
                return; // Handle or return early for failed requests
            }
    
            const reader = response.body.getReader();
            const { done, value } = await reader.read();
            const decodedResponse = new TextDecoder().decode(value);
            setshowSuccessMessageBox(decodedResponse)
    
            // Further processing based on successful response
        } catch (error) {
            console.error('Error fetching data:', error);
            // Handle network errors or other exceptions
        }
    }
    const handleCadastro = () =>{
        if(vulgo !== '' && email !== '' && senha !== '' && confirmSenha && (senha == confirmSenha))
        {
            sendCastro()
        }
        else
        {
            setShowMessageBox('Preencha todos os campos')
        }
    }
    if (showMessageBox)
        setTimeout(() => {
            setShowMessageBox(null)
        }, 5000)
    if(showErrorMessageBox)
        setTimeout(() => {
            setShowErrorMessageBox(null)
        }, 5000)
    if(showSuccessMessageBox)
        setTimeout(() => {
            setshowSuccessMessageBox("Sendo redirecionado para login...")
            setTimeout(()=>{
                navigate("/login")
            },5000)
        }, 5000)
    return <div className="Registro">
        {showMessageBox &&
        <MessageBox 
            message={showMessageBox}
            typeMessage={'aviso'}
        />
        }
        {showErrorMessageBox && 
         <MessageBox 
            message={showErrorMessageBox} 
            typeMessage={'error'}
         />
        }
         {showSuccessMessageBox && 
         <MessageBox 
            message={showSuccessMessageBox} 
            typeMessage={'confirmar'}
         />
        }
        <h1>Cadastro</h1>
        <hr />
            <div className="formularioRegistro">
                <TextField
                    label={'Vulgo / Grupo'}
                    labelPosition={'top'}
                    mandatory={true}
                    placeholder={'Vulgo / Grupo'}
                    onChanged={setVulgo}
                    value={vulgo}
                />
                <TextField
                    label={'Email'}
                    labelPosition={'top'}
                    mandatory={true}
                    placeholder={'Email'}
                    onChanged={setEmail}
                    value={email}
                />
                <PasswordField
                    label={'Senha'}
                    labelPosition={'top'}
                    onChange={(_senha) => {
                        setSenha(_senha)
                     }}
                    validate={false}
                    placeholder='Senha'
                />
                <PasswordField
                    label={'Confirmar Senha'}
                    labelPosition={'top'}
                    onChange={(_senha) => { 
                        setconfirmSenha(_senha)
                    }}
                    validate={false}
                    placeholder='Confirmar Senha'
                />
            </div>
            <div className="actions">
                <button onClick={handleCadastro} >Cadastrar</button>
                <Link to={'/login'}>Já possui conta? Faça login</Link>
            </div>
    </div>
}