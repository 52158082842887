import { MenuActionButton } from "./dashboardMenu"
import mycard from '../Arquivos/Imgs/mycard.png'
import  money from '../Arquivos/Imgs/money.png'
import cashback  from '../Arquivos/Imgs/cashback.png'
import card  from '../Arquivos/Imgs/card.png'
import { getEntityFromMongoDb, useMongo, WithMongoCollection, WithMongoPath } from "../MongoDb/mongoContext"
import { useEffect, useState } from "react"
import { dictionaryToArray } from "../Helpers/dictionaryToArray"
import { FlexGridTable } from "../Components/Table/flexTableGrid"

const DashboardTop = ()=>{

    //SALDO
    const SaldoImg = ()=>{
        return <img src={money}/>
    }
    const SaldoAmount = ()=>{
        const {state} = useMongo()
        const {saldo} = state ?? 0
        return <div className='saldo'>
            <label>Saldo</label>
            <strong>R$ {Number(saldo).toFixed(2)}</strong>
        </div>
    }
    //CARTOES DISPONIVEIS
    const CartoesImg = () =>{
        return <img className="card" src={card}/>
    }
    const CartoesDisponiveis = ()=>{
        const {collection} = useMongo()

        const [cartoes,setCartoes] = useState([])
        useEffect(()=>{
            const getCartoes = async () =>{
                const _cartoes = await getEntityFromMongoDb({collection},'cartoes')
                if(_cartoes)
                {
                    const cartoesArray = dictionaryToArray(_cartoes)
                    if(cartoesArray.length > 0)
                        setCartoes(cartoesArray)
                }   
            }
            getCartoes()
        })
        return <div className='cartoes'>
            <label>Cartões disponiveis</label>
            <strong>{cartoes?.length}</strong>
        </div>
    }
    //CARTOES COMPRADOS
    const CartoesCompradosImg = () =>{
        return <img  src={mycard}/>
    }
    const  CartoesComprados = ()=>{
        const {state} = useMongo()
        const meusCartoes = dictionaryToArray(state)
        return <div className="meusCartoesCount">
            <label>Meus cartões</label>
            <strong>{meusCartoes?.length ?? 0}</strong>
        </div>
    }
    //--Pontos
    const MeusPontosImg = ()=> {
        return <img src={cashback}/>
    }
    const MeusPontos = ()=>{
        const {state} = useMongo()
        const {pontos} = state ?? 0
        return <div className="meusPontos">
            <label>Pontos</label>
            <strong>{pontos}</strong>
        </div>
    }
    //--
    return <div className='dashboardTop'>
        <MenuActionButton
            left={<SaldoImg/>}
            middle={<SaldoAmount/>}
            right={<button>Add saldo</button>}
        />
        <MenuActionButton
            left={<CartoesImg/>}
            middle={<CartoesDisponiveis/>}
        />
        <MenuActionButton
            left={<CartoesCompradosImg/>}
            middle={<WithMongoPath path={['cartoes']}><CartoesComprados/></WithMongoPath>}
            right={<button>Ver</button>}
        />
         <MenuActionButton
            left={<MeusPontosImg/>}
            middle={<WithMongoPath path={[]}><MeusPontos/></WithMongoPath>}
            right={<button>Trocar pontos</button>}
        />
    </div>
}
const DashboardContent = ()=>{
    const {state} = useMongo()
    const cartoesArray = dictionaryToArray(state)
    const columns = [
        {
            heading: 'Bandeira',
            orderBy: 'bandeira',
            draggable: true,
            factory: ({bandeira})=>
                <span>{bandeira}</span>
        },
        {
            heading: 'Bin',
            orderBy: 'numero',
            draggable: true,
            factory: ({numero})=>
                <span>{numero}</span>
        },
        {
            heading: 'Validade',
            orderBy: 'data',
            draggable: true,
            factory: ({data})=>
                <span>{data}</span>
        },
        {
            heading: 'Nome',
            orderBy: 'nome',
            draggable: true,
            factory: ({nome})=>
                <span>{nome}</span>
        },
        {
            heading: 'Nivel',
            orderBy: 'nivel',
            draggable: true,
            factory: ({nivel})=>
                <span>{nivel}</span>
        },
        {
            heading: 'Valor',
            orderBy: 'valor',
            draggable: true,
            factory: ({valor})=>
                <span>{valor}</span>
        }
    ]
    return <div className='dashboardContent'>
        <FlexGridTable 
            data={cartoesArray}
            dataColumns={columns}
            allowReorderColumns={false}
        />
    </div>
}
export default function Dashboard()
{
    return <div className='Dashboard'>
        <DashboardTop/>
        <WithMongoCollection kind="cartoes">
            <WithMongoPath path={[]}>
                <DashboardContent/>
            </WithMongoPath>
        </WithMongoCollection>
    </div>
}