import React, { createContext, useContext, useEffect, useState } from 'react';
import * as Realm from 'realm-web';
import { arrayToDictionary } from '../Helpers/dictionaryToArray';
import { getAPIPortNumber, port } from '../Helpers/location';
import { domain } from '../Helpers/globals';
import { parseAllCookies, removeCookie } from '../Helpers/cookies';
import FSHasher from '../Helpers/FSHASHER';
import { getNewRandomUUID } from '../Helpers/crypto';
import { useNavigate } from 'react-router-dom';

export const MongoContext = createContext();
export const apiPortNumber = getAPIPortNumber()
export const apiUrl = port === '3000'? domain : `fsocietyinfo.azurewebsites.net`
export let roomKind = null

/**
 * Flattens an object into an array of key-value pairs.
 * @param {object} item - The object to flatten.
 * @param {string[]} [path=[]] - The current path of the object being flattened.
 * @param {object[]} [into=[]] - The array to store the flattened key-value pairs.
 * @returns {object[]} - The flattened key-value pairs.
 */
export const flatten = (item, path = [], into = []) => {
    for (const fieldName in item) {
        const value = item[fieldName]
        if (typeof value === 'object' && !Array.isArray(value)) {
            flatten(value, [...path, fieldName], into)
        } else into.push({ fieldName, value, path })
    }
    return into
}

/**
 * Clears the login cookies.
 */
export function ClearLoginCookies() {
    removeCookie('auth')
    removeCookie('firstName')
    removeCookie('lastName')
    removeCookie('profilePicture')
    removeCookie('lifeSpan')
    removeCookie('originalAccount')
    removeCookie('databaseName')
}

/**
 * Provides a MongoDB context for the application.
 * @component
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components.
 * @returns {ReactNode} The rendered component.
 */
export const MongoProvider = ({ children }) => {
    const app = new Realm.App({ id: 'application-0-qtflv'})
    const [client, setClient] = useState(null);
    const cookies = parseAllCookies()
    const { auth, databaseName = 'fsociety' } = cookies


    /**
    * Checks the lifespan of a cookie and performs necessary actions if expired.
    * @returns {boolean} Returns true if the cookie is still valid, false otherwise.
    */
    const CheckLifeSpanCookie = () => {
        
        const {lifeSpan} = cookies ?? {}
        if (!lifeSpan) {
            ClearLoginCookies()
            return false
        }
        //get current date and time
        const currentDateTime = new Date()
        //get the lifeSpan date and time
        const lifeSpanDateTime = new Date(lifeSpan)
        //compare the two
        if (currentDateTime > lifeSpanDateTime) {
            ClearLoginCookies()
            alert('Your session has expired. You will be redirected to the login page.')
            window.location.reload()
            return false
        }
        return true
    }

    //API
    const fsocietyApi = () => {
        const send = (message) => {
            //before sending check if the lifeSpan time has not expired.
            if (!CheckLifeSpanCookie())
                return
            //return
            try {
                fetch(`https://${apiUrl}:${apiPortNumber}/api/changes`, {
                    method: 'POST',
                    //credentials: 'same-origin',
                    headers: {
                        'Content-Type': 'application/json',
                        'Token': auth,
                        'DatabaseName': databaseName,
                        'Authorization': `Bearer ${auth}`
                    },
                    body: JSON.stringify(message)
                }).then((response) => {
                    if (response.status === 401)
                        console.log("Nao autorizado")
                })
            } catch (ex) {
                console.log(ex)
            }
        }
        return { send }
    }
    useEffect(() => {
        const connectToMongo = async () => {
            try {
                if (!app.currentUser?.isLoggedIn) {
                    const apiKey = 'RNaSkizzikG6LRKS1SPB1a3WZIT1D8WtsjF080rXktZvWvl8A31O0ymhGBOo2PhQ'
                    if(apiKey)
                        await app.logIn(Realm.Credentials.apiKey(apiKey))
                }
                const db = app.currentUser.mongoClient('mongodb-atlas').db(databaseName);
                !client && setClient(db);
            }
            catch (error) {
                console.error('Error connecting to MongoDB:', error);
            }
        };

        connectToMongo();
    }, [databaseName]);
    const context = {
        ...client,
        api: fsocietyApi
    }
    return <MongoContext.Provider value={context}>{children}</MongoContext.Provider>;
}
/**
 * Logs out the current user from MongoDB.
 * @returns {Promise<void>} A promise that resolves when the user is logged out.
 */
export const logoutFromMongoDb = async () => {
    const app = new Realm.App({ id: 'application-0-qtflv' })
    await app.currentUser.logOut()
}
/**
 * Custom hook that provides access to the MongoContext.
 * @returns {Object} The MongoContext object.
 */
export const useMongo = () => {
    const context = useContext(MongoContext);
    if (!context) {
        return {};
    }
    return context ?? {};
}


/** This takes a complex permission dictionary and breaks it down into a simple one
* @param {dictionary} propertyDictionary - a collection of properties with permissions in them
* @param {Int} resolvedHashedPath - a hash of the path to the current room that has already been calculated
* @returns {dictionary} a permissions object with boolean values for what is or isn't allowed
*/
const resolvePermissionSubDictionary = (propertyDictionary, resolvedHashedPath) => {

    //resolve
    let canUpdate = false
    let canDelete = false
    let canAdd = false
    let canRead = false

    //First set on the basis of the zeros, these are default permissions that get handed down from the root acl files
    canUpdate = propertyDictionary.canUpdate?.allowed?.includes(0)
    canDelete = propertyDictionary.canDelete?.allowed?.includes(0)
    canAdd = propertyDictionary.canCreate?.allowed?.includes(0)
    canRead = propertyDictionary.canRead?.allowed?.includes(0)

    //Second check for disallows
    if (propertyDictionary.canUpdate?.disallowed?.includes(resolvedHashedPath)) canUpdate = false
    if (propertyDictionary.canDelete?.disallowed?.includes(resolvedHashedPath)) canDelete = false
    if (propertyDictionary.canCreate?.disallowed?.includes(resolvedHashedPath)) canAdd = false
    if (propertyDictionary.canRead?.disallowed?.includes(resolvedHashedPath)) canRead = false

    //Lastly check for SPECIFIC allows
    if (propertyDictionary.canUpdate?.allowed?.includes(resolvedHashedPath)) canUpdate = true
    if (propertyDictionary.canDelete?.allowed?.includes(resolvedHashedPath)) canDelete = true
    if (propertyDictionary.canCreate?.allowed?.includes(resolvedHashedPath)) canAdd = true
    if (propertyDictionary.canRead?.allowed?.includes(resolvedHashedPath)) canRead = true

    //properties
    let propertyPermissions = {}
    if (propertyDictionary?.propertyPermissions?.constructor == Object)  //Check if dictionary
        for (const [propertyName, dictionary] of Object.entries(propertyDictionary?.propertyPermissions)) {
            let propertyPermissionSet = resolvePermissionSubDictionary(dictionary, resolvedHashedPath)
            propertyPermissions[propertyName] = propertyPermissionSet
        }

    //assembly
    const permissions = {
        canUpdate: canUpdate,
        canDelete: canDelete,
        canAdd: canAdd,
        canRead: canRead,
        propertyPermissions: propertyPermissions
    }

    return (permissions)
}

/**
 * Higher-order component that provides MongoDB collection functionality to its children.
 *
 * @param {Object} props - The component props.
 * @param {string} props.kind - The kind of collection.
 * @param {string} props.topicID - The ID of the topic.
 * @param {ReactNode} props.children - The children components.
 * @param {string} props.className - The class name for the component.
 * @param {string} props.name - The name of the component.
 * @returns {ReactNode} The wrapped component with MongoDB collection functionality.
 */
export const WithMongoCollection = ({ kind, topicID, children, className, name }) => {
    const mongoContext = useMongo();
    const { api } = mongoContext
    const [state, setState] = useState({});
    const [sdsApi, setSdsApi] = useState(api)
    const [permissions, setPermissions] = useState({})
    const [connectedUsers, setUsers] = useState({})
    const [sessionRefreshInterval, setSessionRefreshInterval] = useState(null);

    /**
    * Keeps the session alive by refreshing the current user's custom data.
    * @param {Object} app - The app object.
    * @returns {Promise<void>} - A promise that resolves when the session is kept alive.
    */
    const keepSessionAlive = async (app) => {
        try {
            // Access the current user to keep the session alive
            await app?.currentUser?.refreshCustomData();
        } catch (error) {
            console.error('Error refreshing session:', error);
        }
    };
    roomKind = kind

    /**
    * Fetches initial data from the specified collection.
    * @param {Collection} collection - The MongoDB collection to fetch data from.
    */
    const fetchInitialData = async (collection) => {
        try {
            const result = topicID ? await collection.find({ _id: topicID }) : await collection.find();
            const array = topicID ? result?.[0] : arrayToDictionary(result, topicID);
            if(array != undefined || array != null)
                setState(array);
        } catch (error) {
            console.error('Error fetching initial data:', error);
        }
    }
    useEffect(() => {
            const intervalId = setInterval(() => {
                keepSessionAlive(mongoContext)
            }, 10 * 60 * 1000); // Call keepSessionAlive every 10 minutes
            setSessionRefreshInterval(intervalId);
        const watchCollection = async () => {
            try {
                if (!mongoContext?.collection) return;

                const collection = mongoContext?.collection(kind);
                if(!collection && !mongoContext?.currentUser?.isLoggedIn)
                    return
                const watcher = collection.watch();
                // Fetch initial data 
                await fetchInitialData(collection);
                // Use for-await-of loop to listen to changes
                for await (const change of watcher) {
                    // Update the collectionState when changes occur
                    if (topicID) {
                        if (topicID === change.documentKey._id)
                            setState(() => ({
                                ...change.fullDocument,
                            }));
                    } else
                        setState((prevCollectionState) => ({
                            ...prevCollectionState,
                            [change.documentKey._id]: change.fullDocument,
                        }));
                }
            } catch (error) {
                console.error('Error in watchCollection:', error);
            }
        };

        watchCollection();
        return () => {
             clearInterval(sessionRefreshInterval);
        }
    }, [kind, topicID, mongoContext?.currentUser, mongoContext?.collection]);
    //----API SECTIONs
    let waitInterval = 500 // initial ½s
    const waitForConnection = (callback) => {
        // If the websocket can' return a null readyState then wait ½s and try again
        if (!sdsApi)
            return

        if (sdsApi) {
            waitInterval = 500 // reset to initial ½s
            return callback()
        }

        setTimeout(() => waitForConnection(callback), waitInterval)
        waitInterval *= 1.1 // increase wait time exponentially
        if (waitInterval > 2000)
            waitInterval = 2000 // don't exceed a limit os 2s
    }

    const send = (data, callback) =>
        waitForConnection(() => {
            // If data is empty it won't send the request.
            sdsApi.send(data)
            //callback?.()
        })
    const updateWebsocketFields = (changes) => {
        let sendMessage = []
        for (let change of changes) {
            sendMessage.push({
                fieldName: change?.fieldName,
                value: change.value,
                path: change?.path
            })
        }
        let entityId = topicID
        if (!entityId) {
            entityId = changes[0]?.path?.shift()
        }
        send({
            changes: [{ collection: kind, id: entityId, values: sendMessage }]
        })
    }

    const updateWebsocketField = (fieldName, value, path) => {

        if (topicID)
            send({
                changes: [{ id: topicID, collection: kind, values: [{ fieldName, value, path }] }]
            })
        else {
            const id = path?.shift()
            let values = {}

            if (typeof value === 'object' && !Array.isArray(value))
                values = flatten(value)
            else
                values = [{ fieldName: fieldName, path, value: value }]
            send({
                changes: [{ id, collection: kind, values: values }]
            })
        }
    }
    const updateItem = (id = '', values) =>
       send({
            changes: [{ id: topicID ?? id, collection: kind, values: flatten(values) }]
        })
    const updateCollection = (path = [], values, id = '') =>
       send({
            changes: [{ id: topicID ?? id, collection: kind, values: flatten(values, path) }]
        })
    const deleteItems = (path = [], ...ids) => {
        const deletions = []
        if (ids.length == 0)
            deletions.push({ id: path, collection: kind })
        else
            for (let id of ids)
                deletions.push({ id, collection: kind, path: path })
        send({
            deletions: deletions
        })
    }
    const deleteCollection = (path = [], ...ids) => {
        const deletions = []
        for (let id of ids)
            deletions.push({ id: topicID, collection: kind, values: [{ fieldName: id, path: path, value: null }] })
        send({
            changes: deletions
        })
    }
    const doAction = (name = '', args = {}) =>
        send({
            actions: [{ action: name, args }]
        })
    const clone = (cloningTarget, newID) => {
        const values = flatten(cloningTarget, newID)
        send({
            changes: [{ values }]
        })
    }
    //----End API SECTIONs

    const context = {
        ...mongoContext,
        state: state,
        permissions,
        connectedUsers,
        updateWebsocketField,
        updateWebsocketFields,
        updateItem,
        updateCollection: updateCollection,
        deleteItems,
        deleteCollection: deleteCollection,
        doAction,
        clone,
        send
    }
    if (name && state)
        context[name] = state
    else
        context.state = { ...context.state, ...state }

    context.readyState = sdsApi?.readyState
    return (
        <MongoContext.Provider className={className} value={context}>
            {children}
        </MongoContext.Provider>
    );
};

/**
 * Retrieves an entity from MongoDB based on the collection name and topic ID.
 * @param {string} collectionName - The name of the MongoDB collection.
 * @param {string} topicID - The ID of the topic to retrieve. If not provided, retrieves all entities from the collection.
 * @returns {Promise<any>} - A promise that resolves to the retrieved entity/entities from MongoDB.
 */
export const getEntityFromMongoDb = async (mongoContext,collectionName, topicID) => {
    if(!mongoContext)
        return
    if (!mongoContext?.collection) 
        return

    const collection = mongoContext?.collection(collectionName);
    if(!collection) 
        return

    // Fetch initial data
    const result = topicID ? await collection.find({ _id: topicID }) : await collection.find();
    return topicID ? result?.[0] ?? {} :result;
}
/**
 * A higher-order component that provides MongoDB context to its children.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.path - The path to the MongoDB data.
 * @param {ReactNode} props.children - The children components.
 * @returns {ReactNode} The component with MongoDB context.
 */
export const WithMongoPath = ({ path = [], children }) => {
    const context = useMongo();
    //const [state, setState] = useState(null);
    const reduce = (path, dataObject = {}) => {
        let context = dataObject
        for (const member of path) {
            if (!member) continue
            context =
                context[member] ??
                context[member?.toLowerCase()] ??
                {}
        }
        return context
    }
    const state = reduce(path, context.state)
    path = [...(context.path ?? []), ...path]
    const setValues = (fieldPaths = [], newValues = []) => {
        const valueArray = []
        for (let i = 0; i < fieldPaths.length; i++) {
            const useReceivedPath = fieldPaths[i]?.useReceivedPath
            const fieldPath = fieldPaths[i]?.path
            const subPath = fieldPath
            const values = {
                fieldName: '',
                path: [],
                value: ''
            }
            if (useReceivedPath)
                values.path = fieldPath
            else {
                if (Array.isArray(subPath) && subPath)
                    values.path = [...path, ...subPath]
                else if (subPath)
                    values.path = [...path, subPath]
                else
                    values.path = path
            }

            values.fieldName = fieldPaths[i]?.fieldName
            values.value = newValues[i]
            valueArray.push(values)
        }
        context.updateWebsocketFields(valueArray)
    }

    const setValuesFor = (changesToSave) => {
        const fieldPaths = []
        const newValues = []
        for (const change of changesToSave) {
            fieldPaths.push({ fieldName: change?.key, path: change?.path, useReceivedPath: change?.useReceivedPath  })
            newValues.push(change.value)
        }
        setValues(fieldPaths, newValues)
    }


    const setValue = (fieldPath, newValue) => {
        const subPath = fieldPath.split('.')
        const field = subPath.pop()
        const newPath = [...path, ...subPath]

        context.updateWebsocketField(field, newValue, newPath)
    }

    const setValueFor = (fieldPath) => (newValue) => {
        setValue(fieldPath, newValue)
    }
   
    const setTrimmedValueFor = (fieldPath) => (newValue) => {
        setValue(fieldPath, newValue && String(newValue).trim())
    }

    const addItem = (newValue, id = getNewRandomUUID()) => {
        const fieldPaths = []
        const newValues = []
        const changesToSave = []

        for (const [key, value] of Object.entries(newValue)) {
            fieldPaths.push({ fieldName: key, path: id })
            newValues.push(value)

            changesToSave[key] = { value: value, path: [] }
        }
        for (const change of changesToSave) {
            if (change) {
                fieldPaths.push({ fieldName: change?.key, path: change?.path })
                newValues.push(change.value)
            }
        }
        setValues(fieldPaths, newValues)
    }

    const removeItem = (id) => context.deleteItems(path, id)
    const removeCollection = (id) => context.deleteCollection(path, id)
    const subContext = {
        ...context,
        path,
        state,
        setValueFor,
        setValuesFor,
        setTrimmedValueFor,
        addItem,
        removeItem,
        removeCollection: removeCollection
    }
    return <MongoContext.Provider value={subContext}>
        {children}
    </MongoContext.Provider>;
};
export function TestMongoProvider({ children, state = {}, permissions = {}, ...props }) {
    const context = {
        state,
        permissions,
        updateCollection: ()=>{},
        removeItem: ()=>{},
        addItem: ()=>{},
        setValuesFor: ()=>{},
        setValueFor: ()=>{},
        removeCollection: ()=>{},
        setTrimmedValueFor: ()=>{},
        updateWebsocketField: ()=>{},
        ...props
    }
    return (
        <MongoContext.Provider value={context}>
            {children}
        </MongoContext.Provider>
    )
}