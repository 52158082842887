import Logo from "../Components/logo";

import { Link, useNavigation } from "react-router-dom";
import { useEffect, useState } from "react";
import ThemeMenu from "../Components/ThemeMenu/themeMenu";

export const MenuActionButton = ({left, middle, right}) => {
    let width = 33.33
    //Only one
    if(left && !middle && !right)
        width = 100
    if(middle && !left && !right)
        width = 100
    if(right && !middle && !right)
        width = 100
    //Two
    if(left && middle && !right)
        width = 50
    if(left && right && !middle)
        width = 50
    if(middle && right && !left)
        width = 50

    return  <div className='menuActionButton'>
        {left && <div style={{width: `${width}% !important`}} className='left'>{left}</div>}
        {middle &&<div style={{width: `${width}% !important`}} className='middle'>{middle}</div>}
        {right && <div style={{width: `${width}% !important`}} className='right'>{right}</div>}
    </div>
}

export default function DashboardMenu(){
    const initialSection = window.location.hash.split('/')?.[1]
    const  [activeSection, setActiveSection ] = useState(initialSection)
    const isRegraActive = activeSection == 'regras'
    const isMeuCartoes = activeSection == 'meuscartoes'
    const isDashboard = activeSection == 'dashboard' || '/'
    const handleChange = (newSection)=>{
        setActiveSection(newSection)
    }
    return <div className='DashboardMenu'>
        <div className='menuTopContainer'>
            <Logo/>
        </div>
        <div className='menuBottomContainer'>
            <div className='container'>
                <Link onClick={()=>handleChange('dashboard')} className={`menuItem ${isDashboard ? 'active':''}`} to={'/dashboard'}>Dashboard</Link>
                <Link onClick={()=>handleChange('meuscartoes')}className={`menuItem ${isMeuCartoes ? 'active':''}`} to={'/meuscartoes'}>Meus Cartões</Link>
                <Link onClick={()=>handleChange('regras')}className={`menuItem ${isRegraActive ? 'active':''}`} to={'/regras'}>Regras</Link>
            </div>   
            <ThemeMenu/>
        </div>
       
    </div>
}